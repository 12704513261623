// Resource hub includes both Contentful data and third-party ON24 API webinars data
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Resource } from './interfaces/resource.interface';
import Layout from '../layout/layout';

import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { graphql } from 'gatsby';
import { On24Entry } from '../webinars/interfaces/on24.interface';
import { ResourceBrowser } from './components/ResourceBrowser';
import { HeroBlade } from '../common/refresh-blades/HeroBlade';
import { useCMSState } from '@internal/useCMSHook';
import { ResourceHubPageData } from './interfaces/resourcehub-pagedata.interface';
import { useCMSCollectionState } from '@internal/useCMSCollectionHook';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';

export const query = graphql`
  query($locale: String!) {
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    resources: allResource(
      filter: { locale: { eq: $locale }, resourceType: { ne: "Webinar" } }
      sort: { fields: orderDate, order: DESC }
    ) {
      nodes {
        ...resourceFragment
      }
    }
    webinars: allOn24Webinar(
      filter: { goodafter: { ne: null } }
      sort: { fields: goodafter, order: DESC }
    ) {
      nodes {
        id
        title
        url
        description
        thumbnail
        url
        filtergroups {
          name
          filters {
            name
          }
        }
        tags {
          name
        }
        isactive
        displaytimezonecd
        livestart
        goodafter
        liveend
        archivestart
        archiveend
        createtimestamp
      }
    }

    englishPageData: mrkTemplateContentOnly(
      meta_templatePath: { eq: "resource-hub" }
      locale: { eq: "en-US" }
    ) {
      contentfulId
      json_config {
        headTags {
          title
        }
        filters {
          industries
          topics
          resourceTypes
        }
        hero {
          title
          body
          streamId
          streamLinkText
        }
        body {
          title
          dropdownHeader1
          dropdownHeader2
          dropdownHeader3
          dropdownAll
          noResultsMessage
          loadMore
        }
      }
    }
    pageData: mrkTemplateContentOnly(
      meta_templatePath: { eq: "resource-hub" }
      locale: { eq: $locale }
    ) {
      contentfulId
      json_config {
        headTags {
          title
        }
        filters {
          industries
          topics
          resourceTypes
        }
        hero {
          title
          body
          streamId
          streamLinkText
        }
        body {
          title
          dropdownHeader1
          dropdownHeader2
          dropdownHeader3
          dropdownAll
          noResultsMessage
          loadMore
        }
      }
    }
  }
`;

export interface ResourceHubParams extends GatsbyTemplateProps<AppContextProps> {
  data: {
    resources: {
      nodes: Resource[];
    };
    webinars: {
      nodes: On24Entry[];
    };
    pageData: ResourceHubPageData;
    englishPageData: ResourceHubPageData;
    headerData: ContentfulNavNavigationGroup;
    footerData: MainFooter;
  };
}

const ResourceHub: FC<ResourceHubParams> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { webinars, headerData, footerData, pageData, englishPageData, resources } = data;
  if (config.targetEnv === 'staging') {
    footerData = useCMSState<MainFooter>(footerData);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    pageData = useCMSState<ResourceHubPageData>(pageData);
    englishPageData = useCMSState<ResourceHubPageData>(englishPageData);
    resources = useCMSCollectionState<{ nodes: Resource[] }>('resource', resources);
  }

  const { hero, headTags } = pageData.json_config;
  const heroImageUrl = 'https://www.cloudflare.com/img/resource-hub/resource-hub-header.png';

  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>{headTags.title}</title>
      </Helmet>

      <HeroBlade
        page={{
          contentTypeId: 'page',
          pageName: 'resource-hub',
          contentfulId: '',
          relativePath: '/resource-hub',
          metaTitle: headTags.title,
          metaDescription: headTags.title,
        }}
        blade={{
          contentTypeId: 'bladeHeroAnimation',
          contentfulId: '',
          title: hero.title,
          copy: hero.body,
          button1ExtraClass: '',
          popupStreamId: hero.streamId,
          popupText: hero.streamLinkText,
          image: { file: { publicURL: heroImageUrl } },
          includeVideo: false,
          backgroundColor: 'light-blue',
          leftLinkOptions: 'hide',
          sectionPadding: null,
        }}
      />

      <ResourceBrowser
        pageData={pageData}
        englishPageData={englishPageData}
        resources={resources.nodes}
        webinars={webinars.nodes}
      />
    </Layout>
  );
};

export default ResourceHub;
